<template>
  <div class="my-20 w-full gap-4 text-center">
    <div
      class="bg-gradient-to-r from-[#EA5F05] to-[#ffa200] bg-clip-text text-center text-4xl font-bold first:text-transparent"
    >
      Need more Infomation?
    </div>

    <div class="mt-2 text-center text-sm font-normal text-gray-800 md:text-lg">
      Feel free to reach out to us today, and we'll get back to you within one
      business day.
      <br />
      Be sure to include your location so as member of our global sales team can
      assure communication in your closest time zone.
    </div>
    <div
      class="mt-8 inline-flex w-auto items-center justify-center gap-2.5 rounded-[100px] border-2 border-[#ed6a05] px-10 py-3"
    >
      <button
        class="text-base font-semibold text-[#ef7405]"
        @click="useFreshdesk().openContactUsForm"
      >
        Contact Us
      </button>
    </div>
  </div>
</template>
