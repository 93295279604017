<script lang="ts" setup>
import { onMounted, onBeforeUnmount, ref } from "vue";

const scrollContent = ref<HTMLElement | null>(null);

const handleScroll = () => {
  const scrollY = window.scrollY;
  const moveAmount1 = scrollY * 0.05;
  const moveAmount2 = scrollY * 0.1 * -1;
  const moveAmount3 = scrollY * 0.1;

  if (scrollContent.value) {
    const children = scrollContent.value.children;
    if (children.length >= 3) {
      (children[0] as HTMLElement).style.transform =
        `translateX(${moveAmount1}px)`;
      (children[1] as HTMLElement).style.transform =
        `translateX(${moveAmount2}px)`;
      (children[2] as HTMLElement).style.transform =
        `translateX(${moveAmount3}px)`;
    }
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
  <div class="my-20 w-full gap-4 text-center">
    <div
      class="bg-gradient-to-r from-[#EA5F05] to-[#ffa200] bg-clip-text text-center text-2xl font-bold text-transparent md:text-4xl"
    >
      We Support 41 Languages for
      <br />
      Realtime Interpretation.
    </div>

    <div class="my-4 text-base font-normal text-gray-800 md:text-lg">
      Break language barriers with real-time interpretation, bridging
      communication gaps effortlessly.
    </div>

    <div class="relative flex items-center overflow-hidden">
      <div
        ref="scrollContent"
        class="relative left-1/2 flex -translate-x-[55%] flex-col"
      >
        <img
          src="public/landing/Language_first.svg"
          class="-mb-10 h-[128px] max-w-none shrink-0 transition-transform duration-300 ease-out"
        />
        <img
          src="public/landing/Language_second.svg"
          class="-mb-10 ml-80 max-w-none shrink-0 transition-transform duration-300 ease-out"
        />
        <img
          src="public/landing/Language_third.svg"
          class="h-[128px] max-w-none shrink-0 transition-transform duration-300 ease-out"
        />
      </div>
    </div>
  </div>
</template>
