<template>
  <div
    id="product"
    class="mb-20 flex h-80 w-full flex-col justify-end gap-4 text-center lg:h-60"
  >
    <div
      class="bg-gradient-to-r from-[#EA5F05] to-[#ffa200] bg-clip-text text-center text-2xl font-bold first:text-transparent md:text-4xl"
    >
      Speak Your Language,
      <br />
      EventCAT Translates Instantly to Your Language
    </div>

    <div class="mt-4 text-sm font-normal text-[#5b5b5b] md:text-base">
      EventCAT provides a powerful AI-based collaboration solution with
      real-time translation.
    </div>
  </div>

  <div
    class="flex flex-col items-center justify-center gap-16 px-10 md:flex md:flex-col md:items-center md:justify-center md:px-10"
  >
    <div class="flex flex-col justify-between gap-6 lg:flex-row">
      <div class="inline-flex w-max flex-col">
        <div class="flex w-80 flex-col items-start gap-4 md:w-[500px]">
          <img src="public/landing/OnlineMeeting_icon.svg" class="h-12" />

          <div class="text-3xl font-bold text-black">Online Meetings</div>
          <div
            class="text-sm font-normal leading-relaxed text-gray-600 md:text-base"
          >
            Invite the EventCAT bot to your online call.
            <br />
            It translates the speech of all participants in real time. We
            currently support over 41 languages.
          </div>

          <div
            class="flex h-48 w-fit flex-row gap-4 rounded-md bg-gray-100 p-6 md:gap-8"
          >
            <img
              src="https://cmdhxpwmlalsaqwxippw.supabase.co/storage/v1/object/public/images/hero/OnlineMeeting_avatar.jpg"
              class="size-12 rounded-full md:size-16"
            />
            <div class="flex h-full flex-col justify-between">
              <div
                class="text-xs font-light leading-normal text-black md:text-base"
              >
                “ EventCAT is a cost-effective, optimal solution for connecting
                and communicating with our organization's diverse language
                groups. "
              </div>
              <div
                class="text-xs font-light leading-normal text-black md:text-sm"
              >
                Multinational Team Leader at at tech startup
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        src="public/landing/Solution_online_meeting.svg"
        class="hidden w-full lg:block lg:w-2/5 xl:w-1/2"
      />
    </div>

    <div class="flex flex-col justify-between gap-6 lg:flex-row">
      <div class="inline-flex w-max flex-col">
        <div class="flex w-80 flex-col items-start gap-4 md:w-[500px]">
          <img src="public/landing/Conference_icon.svg" class="h-12" />

          <div class="text-3xl font-bold text-black">Conference</div>
          <div
            class="text-sm font-normal leading-relaxed text-gray-600 md:text-base"
          >
            View real-time translated subtitles on large screens and personal
            devices.
          </div>

          <div
            class="flex h-48 w-fit flex-row gap-4 rounded-md bg-gray-100 p-6"
          >
            <img
              src="public/landing/OnlineMeeting_avatar.jpeg"
              class="size-12 rounded-full md:size-16"
            />
            <div class="flex h-full flex-col justify-between gap-2">
              <div
                class="text-xs font-light leading-normal text-black md:text-base"
              >
                “ It was very impressive to convey my speech in real-time. I
                think it's a great way to bring people from around the world
                together. "
              </div>
              <div
                class="text-xs font-light leading-normal text-black md:text-sm"
              >
                Dave Clark ,
                <br />
                an award-winning filmmaker pioneering the use of AI
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        src="https://cmdhxpwmlalsaqwxippw.supabase.co/storage/v1/object/public/images/hero/Solution_conference.svg?t=2024-11-04T03%3A56%3A28.352Z"
        class="hidden w-full lg:block lg:w-2/5 xl:w-1/2"
      />
    </div>
  </div>
</template>
